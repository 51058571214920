import React from 'react';
import { GridList, NotificationType, ClippedContent, DateTimeUtils } from 'dyl-components';
import { Icon, Header, Grid, Loader } from 'semantic-ui-react';
import { StringUtils } from 'utils';

import './index.scss';

const formatText = (notification, onOpen, isTime=true) => {
    const contactId = notification.notification_details?.contact_id;
    const accountId = notification.notification_details?.account_id;
    const cnam = notification.notification_details?.cnam_name;
    const type = notification.notification_type;
    const title = notification.notification_details?.title || "N/A";
    const notificationTime = notification.notification_ts;
    const isDescription = (type !== "fax" && type !== "missed_call");
    const header = type === "task" ? <a role="button" onClick={()=> onOpen && onOpen(notification.external_id, type, notification.notification_id)} >{StringUtils.capitalize(notification.notification_details?.task_type)} {StringUtils.capitalize(type)}: {title}</a> 
        : type === "missed_call" ? <>Missed Call: {(!contactId && !accountId) ? cnam : <a href={`/${contactId ? "contact" : "account"}/${contactId ? contactId : accountId}`} target="_blank">{contactId ? notification.notification_details.contact_name : notification.notification_details.account_name}</a>}</>
            : type === "voicemail" ? <>Voicemail: {(!contactId && !accountId) ? cnam : <a href={`/${contactId ? "contact" : "account"}/${contactId ? contactId : accountId}`} target="_blank">{contactId ? notification.notification_details.contact_name : notification.notification_details.account_name}</a>}</>
                : type !== "system" ? <>{StringUtils.capitalize(type)}: <a role="button" onClick={()=> onOpen && onOpen(notification.external_id, type, notification.notification_id)}>{title}</a></> 
                    : `${StringUtils.capitalize(type)}: ${title}`;
    const seconds = DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.DATETIME_LONG_FORMAT), DateTimeUtils.formatEpoch(notificationTime, DateTimeUtils.DATETIME_LONG_FORMAT), 'seconds', DateTimeUtils.DATETIME_LONG_FORMAT);
    const minutes = DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.DATETIME_LONG_FORMAT), DateTimeUtils.formatEpoch(notificationTime, DateTimeUtils.DATETIME_LONG_FORMAT), 'minutes', DateTimeUtils.DATETIME_LONG_FORMAT);
    const hours = DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.DATETIME_LONG_FORMAT), DateTimeUtils.formatEpoch(notificationTime, DateTimeUtils.DATETIME_LONG_FORMAT), 'hours', DateTimeUtils.DATETIME_LONG_FORMAT);
    const days = DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.DATETIME_LONG_FORMAT), DateTimeUtils.formatEpoch(notificationTime, DateTimeUtils.DATETIME_LONG_FORMAT), 'days', DateTimeUtils.DATETIME_LONG_FORMAT);
    const weeks = DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.DATETIME_LONG_FORMAT), DateTimeUtils.formatEpoch(notificationTime, DateTimeUtils.DATETIME_LONG_FORMAT), 'weeks', DateTimeUtils.DATETIME_LONG_FORMAT);
    const months = DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.DATETIME_LONG_FORMAT), DateTimeUtils.formatEpoch(notificationTime, DateTimeUtils.DATETIME_LONG_FORMAT), 'months', DateTimeUtils.DATETIME_LONG_FORMAT);
    const years = DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.DATETIME_LONG_FORMAT), DateTimeUtils.formatEpoch(notificationTime, DateTimeUtils.DATETIME_LONG_FORMAT), 'years', DateTimeUtils.DATETIME_LONG_FORMAT);
    let formatTime = `${seconds}s`;
    if(seconds > 60){
        formatTime = `${minutes}m`;
    }
    if(minutes > 60){
        formatTime = `${hours}h`;
    }
    if(hours > 12){
        formatTime = `${days}d`;
    }
    if(days > 7){
        formatTime = `${weeks}w`;
    }
    if(weeks > 4){
        formatTime = `${months}mo`;
    }
    if(months > 12){
        formatTime = `${years}yr`;
    }
    return (
        <>  
            <Grid>
                <Grid.Column className="Notification__Header" width={isTime ? 11 : 16} style={{paddingRight: 0}}>
                    <b>{header}</b>
                </Grid.Column>
                {isTime &&
                <>
                    <Grid.Column width={1} className="Notification__Time" style={{paddingLeft: 0, paddingRight: 0}} textAlign='center'>
                        &#x2022;
                    </Grid.Column>
                    <Grid.Column width={4} className="Notification__Time" style={{paddingLeft: 0, paddingRight: 0}} textAlign='left'>
                        {formatTime} ago 
                    </Grid.Column>
                </>
                }
            </Grid>
            {isDescription &&
                <ClippedContent position="bottom center">
                    {type === "voicemail" ? `VM Box ${notification.notification_details?.voicemail}` : notification.notification_details?.description}
                </ClippedContent> 
            }
        </>
    )
}

export const NotificationRow = ({notification, isStatus=true, isTime, onClear, onOpen, isBorder=false}) => {
    const isSnoozed = notification?.snoozed;
    return (
        <GridList.Row className={`Notifications__container${isBorder ? '--border' : ''} ${!isTime ? 'Notification' : 'NotificationCenter'}--${notification.notification_id}`}>
            {isStatus && !isSnoozed &&
                <GridList.Cell width={1}>
                    <Icon className="fa-solid fa-circle" size='small' />
                </GridList.Cell>
            }
            {isSnoozed && 
                <GridList.Cell width={1}>
                    <Icon className="fa-solid fa-alarm-snooze" size='small' />
                </GridList.Cell>
            }
            <GridList.Cell width={2}>
                <NotificationType name={notification.notification_type} />
            </GridList.Cell>
            <GridList.Cell>
                {formatText(notification, onOpen, isTime)}
            </GridList.Cell>
            <GridList.Cell width={2}>
                <Icon className="fa-solid fa-xmark" size='large' onClick={()=> onClear && onClear(notification.notification_id)} style={{ cursor: 'pointer' }}/>
            </GridList.Cell>
        </GridList.Row>
    )
}

export const Notifications = ({notifications=[], isStatus, isTime, onClear, onOpen, isBorder, loading=false }) => {
    return (
        <GridList>
            {loading ?
                <Loader active inline='centered' size='big'/>
            :
                notifications.length !== 0 ?
                    notifications.map((notification, index)=>{
                        return (
                            <NotificationRow 
                                key={index}
                                notification={notification}
                                isStatus={isStatus}
                                isTime={isTime}
                                onClear={onClear}
                                onOpen={onOpen}
                                isBorder={isBorder}
                            />
                        )
                    })
                :
                    <Grid textAlign='center'>
                        <Grid.Row style={{paddingTop: 40, paddingBottom: 30}}>
                            <div className='Notifications__Circle'>
                                <Icon className="fa-solid fa-party-horn" size='huge'/>
                            </div>
                        </Grid.Row>
                        <Grid.Row style={{paddingBottom: 0}}>
                            <Header as='h3'>You're all Caught Up!</Header>
                        </Grid.Row>
                        <Grid.Row style={{paddingBottom: 30}}>
                            No unread notifications, keep up the great work!
                        </Grid.Row>
                    </Grid>   
            }
        </GridList>
    )
}
