import React from 'react';
import { Icon } from 'semantic-ui-react';

import './index.scss';

const notificationIcon = (icon) => {
    switch(icon){
        case "task":
            return "fa-solid fa-clipboard-check";
        case "event":
            return "fa-solid fa-calendar";
        case "voicemail":
            return "fa-solid fa-voicemail";
        case "missed_call":
            return "fa-solid fa-phone-flip";
        case "fax":
            return "fa-solid fa-fax";
        case "text":
            return "fa-solid fa-comments";
        case "system":
            return "fa-solid fa-wrench"; 
        case "email":
            return "fa-solid fa-envelope";
        default:
            return "fa-solid fa-triangle-exclamation";
    }
}

const NotficationType = ({ name }) => (
    <div className='NotficationTypeIcon'>
        <Icon className={notificationIcon(name)} />
    </div>
)

export default NotficationType;
