import React from 'react';
import { Form, Ref } from 'semantic-ui-react';

import ExistingLabels from './subcomponents/ExistingLabels';
import NewLabels from './subcomponents/NewLabels';

import './index.scss';

const fieldRef = React.createRef();

const EditableLabels = ({ fields = [], items = [], size = 'small', addLabel = 'Add', info, name, onSave, onUpdate, onRemove, onCheckDuplicate, isLoading , onFormDirty }) => {
    const getPropsByType = (index) => {
        let props = {
            type: fields[index].type || '',
            placeholder: fields[index].placeholder || '',
            isFieldEditable: fields[index].editable
        }

        switch (props.type) {
            case 'input':
                return props;
            case 'checkbox_dropdown':
                props.options = fields[index].options || [];
                return props;
            default:
                return props;
        }
    }


    return (
        <Ref innerRef={fieldRef}>
            <Form className='EditableLabels' size={size} loading={isLoading} autoComplete='off'>
                <ExistingLabels
                    fields={fields}
                    items={items}
                    name={name}
                    info={info}
                    getPropsByType={getPropsByType}
                    onEditLabel={onUpdate}
                    onRemove={onRemove}
                    onFormDirty={onFormDirty}
                />
                <NewLabels
                    fields={fields}
                    name={name}
                    addLabel={addLabel}
                    onSave={onSave}
                    getPropsByType={getPropsByType}
                    onCheckDuplicate={onCheckDuplicate}
                    onFormDirty={onFormDirty}
                />
            </Form>
        </Ref>
    )
};

export default EditableLabels;
