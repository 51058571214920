import React from 'react';
import { Segment, Header, Table as SemanticTable, Pagination } from 'semantic-ui-react';
import { Table, TableLoader, EmptyListPlaceholder } from 'dyl-components';

import './index.scss';

const RecordsTable = ({
    tableBodyClassName = '',
    isTableLoading,
    isSearchResultEmpty,
    isTableEmpty,
    tableEmptyMessage = null,
    TableHeader,
    TableRows,

    hasPagination = true,
    recordsCount,
    recordsLimit = 25,
    activePage,
    onPageChange,

    recordsName,
    emptyListTitle = null,
    emptySubtitle = null,
    emptySubtitleLink,
    emptySubtitleHidden,
    onClick
}) => {
    return (
        <Segment.Group className='RecordsTable'>
            <Segment className='RecordsTable__TableSegment'>
                <div className={`TableSegment__OverflowContainer ${isSearchResultEmpty ? 'TableSegment__OverflowContainer--no-result' : ''}`}>
                    <Table striped={false}>
                        <SemanticTable.Header className='Table__header--primary'>
                            {TableHeader}
                        </SemanticTable.Header>
                        <Table.Body className={tableBodyClassName}>
                            {isTableLoading ? <TableLoader isLoading={isTableLoading} colspan={8} /> : TableRows}
                        </Table.Body>
                    </Table>
                    {!isTableLoading && isSearchResultEmpty &&
                        <Header as='h3' textAlign='center'>
                            No Results Found
                        </Header>
                    }
                    { !isTableLoading && isTableEmpty && tableEmptyMessage === null &&
                            <EmptyListPlaceholder
                                title={emptyListTitle}
                                name={recordsName}
                                subtitle={emptySubtitle}
                                link={emptySubtitleLink}
                                subtitleHidden={emptySubtitleHidden}
                                onClick={onClick}
                            />
                    }
                    {!isTableLoading && isTableEmpty && tableEmptyMessage !== null &&
                        <Header as='h3' textAlign='center'>
                            {tableEmptyMessage}
                        </Header>
                    }

                </div>
            </Segment>
            {recordsCount !== 0 && hasPagination &&
                <Segment textAlign='right'>
                    <Pagination
                        boundaryRange={0}
                        activePage={activePage || 1}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(recordsCount / recordsLimit)}
                        onPageChange={onPageChange}
                        disabled={isTableLoading}
                    />
                </Segment>
            }
        </Segment.Group>
    );
}

export default RecordsTable;
