import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';
import { Link } from 'react-router-dom';

import './index.scss';


const EmptyListPlaceholder = ({ title, name = 'Record', subtitle, link, onClick = () => { }, wrapped = true, position = 'top-right', subtitleHidden }) => {
    const convertPluralName = () => {
        const vowels = ['a', 'e', 'i', 'o', 'u'];
        const lastLetters = name.slice(name.length - 2, name.length);

        if (lastLetters.charAt(1).toLowerCase() === 'y') {
            if (vowels.includes(lastLetters.charAt(0).toLowerCase())) {
                return `${name}s`;
            } else {
                return `${name.slice(0, name.length - 1)}ies`;
            }
        } else {
            return `${name}s`;
        }
    }

    const EmptyHeader = () => (
        <Header as='h3' textAlign='center' className={!wrapped ? 'EmptyListPlaceholder' : ''}>
            {title ? title : `No ${convertPluralName()} Created`}
            {!subtitleHidden && <Header.Subheader>
                {subtitle ? subtitle :
                    <span>
                        Add new by clicking on the {link ?
                            <Link to={link}>+ New {name}.</Link> :
                            <ButtonLink onClick={onClick}>+ New {name}.</ButtonLink>}
                    </span>
                }
            </Header.Subheader>}
        </Header>
    );

    if (wrapped) {
        return <Segment className='EmptyListPlaceholder'><EmptyHeader /></Segment>
    }
    return <EmptyHeader />

};

export default EmptyListPlaceholder;
