import React, { useEffect, useState } from 'react';

import './index.scss';
import { Icon, Menu, MenuItem, Segment } from 'semantic-ui-react';
import SearchableOptions from '../SearchableOptions';
import OfficeViewTeamsSearchResults from '../OfficeViewTeamsSearchResults';
import { ButtonLink, Table } from 'dyl-components';
import { Link } from 'react-router-dom';

const OfficeViewTeams = ({
    teams = [],
    selectedTeamsTab = "pinned",
    onChangeSelectedTab,
    isReadingExtensionsTeamsList = false,
    searchResults = [],
    getExtensions,
    togglePinnedTeams,
    pinnedUsers,
    isReadingUsers,
    pinsMaxed
}) => {
    const [search, setSearch] = useState("");
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);
    const [pinnedUsersDisplay, setPinnedUsersDisplay] = useState([{}, {}, {}])
    const isPinnedTab = selectedTeamsTab === "pinned";

    const searchFunction = async () => {
        try {
            setIsSearchResultsVisible(true);
            await getExtensions(search);
        } catch (e) {
            console.log(e)
        }
    }

    const cancelFunction = async () => {
        setSearch("");
        setIsSearchResultsVisible(false);
    };

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
        if (!value) {
            setIsSearchResultsVisible(false);
        }
    }

    const handlePinChange = (id, pinned) => {
        togglePinnedTeams(id, pinned);
        cancelFunction();
    }
    
    useEffect(() => {
        if (isPinnedTab) {
            if (pinnedUsers && pinnedUsers.length > 0) {
                const [first, second, third] = pinnedUsers;
                const pinnedAux = [first || {}, second || {}, third || {}]
                setPinnedUsersDisplay(pinnedAux)
            } else {
                setPinnedUsersDisplay([{}, {}, {}])
            }
        } else {
            if (pinnedUsers && pinnedUsers.length > 0) {
                setPinnedUsersDisplay(pinnedUsers)
            } else {
                setPinnedUsersDisplay([])
            }
        }
    }, [pinnedUsers])

    return (
        <Segment className='OfficeView__teamsContainer'>
            <div>
                <div style={{overflow: 'auto', paddingBottom: 10, marginBottom: 20}}>
                    <Menu pointing secondary borderless size={'massive'} color='primary'>
                        <MenuItem 
                            name="pinned"
                            active={isPinnedTab}
                            onClick={() => onChangeSelectedTab("pinned")}
                        >
                            Pinned
                        </MenuItem>
                        {teams.map(({id, name}) => (
                            <MenuItem 
                                name={id}
                                active={selectedTeamsTab === id}
                                onClick={() => onChangeSelectedTab(id)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                {isReadingUsers ? (
                    <Segment basic loading />
                ) : (
                    <>
                        <div>
                            {isPinnedTab && (
                                <SearchableOptions
                                    loading={isReadingExtensionsTeamsList}
                                    search={search}
                                    searchFunction={searchFunction}
                                    cancelSearchFunction={cancelFunction}
                                    searchPlaceholder="Search by users or extensions"
                                    onChangeSearch={onChangeSearch}
                                    isSearchResultsVisible={isSearchResultsVisible}
                                    renderSearchResults={
                                        <OfficeViewTeamsSearchResults
                                            searchResults={searchResults}
                                            isReadingExtensionsTeamsList={isReadingExtensionsTeamsList}
                                            togglePinnedTeams={handlePinChange}
                                            pinsMaxed={pinsMaxed} 
                                        />
                                    }
                                    isPaginationVisible={false}
                                    floatingResults
                                />
                            )}
                        </div>
                        <div>
                            <Table celled>
                                <Table.Header primary>
                                    <Table.Row>
                                        <Table.HeaderCell width={3}>User</Table.HeaderCell>
                                        <Table.HeaderCell width={6}>Call</Table.HeaderCell>
                                        <Table.HeaderCell width={1}></Table.HeaderCell>
                                        <Table.HeaderCell width={1}></Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {pinnedUsersDisplay.map((user) => {
                                        const isPinDisabled = isPinnedTab ? !user.pinned : pinsMaxed && !user.pinned
                                        return (
                                            <Table.Row loading={false}>
                                                <Table.Cell>
                                                    <div className='OfficeViewTeams__userCell'>
                                                        <ButtonLink disabled={isPinDisabled} className="OfficeViewTeams__userTack" onClick={(event) => {
                                                            togglePinnedTeams(user.extension_id, !user.pinned)
                                                            event.stopPropagation();
                                                        }}>
                                                            <Icon className={`fa-solid fa-thumbtack OfficeViewTeams__linkIcon${user.pinned ? "--pinned" : ""}`} size={"large"} />
                                                        </ButtonLink>
                                                        <div className='OfficeViewTeams__usernameExtension'>
                                                            {user.first_name && (
                                                                <Link to={`/settings/users/${user.user_id}/general`} target='_blank' rel='noopener noreferrer'>{`${user.first_name} ${user.last_name}`}</Link>
                                                            )}
                                                            <span>
                                                                {user.extension_number ? `Ext. ${user.extension_number}` : ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell />
                                                <Table.Cell>
                                                    {user.user_id && (
                                                        <div className='OfficeViewTeams__voicemailCell'>
                                                            <ButtonLink>
                                                                <Icon className='fa-solid fa-voicemail OfficeViewTeams__linkIcon--pinned' size={"large"} />
                                                            </ButtonLink>
                                                        </div>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {user.user_id && (
                                                        <div className='OfficeViewTeams__voicemailCell'>
                                                            <ButtonLink disabled={!user.voicemail_ext}>
                                                                <Icon className='fa-solid fa-mobile-screen-button OfficeViewTeams__linkIcon--pinned' size={"large"} />
                                                            </ButtonLink>
                                                        </div>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {user.user_id && (
                                                        <div className='OfficeViewTeams__actionsCell'>
                                                            <ButtonLink style={{flex: 1}}>
                                                                <Icon className='fa-solid fa-phone OfficeViewTeams__linkIcon--pinned' size={"large"} />
                                                            </ButtonLink>
                                                            <ButtonLink style={{flex: 1}}>
                                                                <Icon className='fa-solid fa-envelope OfficeViewTeams__linkIcon--pinned' size={"large"} />
                                                            </ButtonLink>
                                                            <ButtonLink style={{flex: 1}}>
                                                                <Icon className='fa-solid fa-comments OfficeViewTeams__linkIcon--pinned' size={"large"} />
                                                            </ButtonLink>
                                                        </div>
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    </>
                )}
            </div>
        </Segment>
    )
}

export default OfficeViewTeams;
