import React, { useState } from 'react';
import {  Grid, Icon, Popup } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';
import { VoicemailPlayback } from 'dyl-components';

const OfficeViewCallRecording = ({ 
    currentCallPhoneNumber,
    duration = 0,
    callRecording,
    voicemail = false
 }) => {
    const [isVoicemailPlaybackOpen, setIsVoicemailPlaybackOpen] = useState(false);

    return (
        <Grid className="OfficeViewCallRecording" columns="equal">
            <Grid.Row>
                <Grid.Column width={3}>
                    {PhoneUtil.formatPhoneNumber(currentCallPhoneNumber)}
                </Grid.Column>
                <Grid.Column>
                    <Popup
                        style={{minWidth:400}}
                        trigger={
                            <Icon
                                className={`fa-solid fa-${voicemail ? "voicemail" : "circle-play"}`}
                                style={{ marginRight: 6, cursor: 'pointer' }} 
                                disabled={!!!callRecording?.url}
                                color="primary"
                            /> 
                        }
                        on="click"
                        content={
                            <VoicemailPlayback
                                onClose={() => setIsVoicemailPlaybackOpen(false)}
                                className={`audio-playback-${callRecording?.sound_id}`}
                                fileName={"Call Recording"}
                                link={callRecording?.url}
                            />
                        }
                        position="bottom center"
                        open={isVoicemailPlaybackOpen}
                        onOpen={() => setIsVoicemailPlaybackOpen(true)}
                    />
                    Duration: {PhoneUtil.timeTicker(duration)}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default OfficeViewCallRecording;