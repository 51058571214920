import ButtonLink from 'dyl-components/atoms/ButtonLink';
import DateTimeUtils from 'dyl-components/utils/DateTimeUtils';
import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';
import VoicemailPlayback from '../VoicemailPlayback';

const DIRECTION_ICONS = {
    inbound: "fa-solid fa-phone-arrow-down-left",
    outbound: "fa-solid fa-phone-arrow-up-right",
    internal: "fa-solid fa-people-pants-simple"
}

const PERSON_TYPE_ICON = {
    person: "fa-solid fa-user",
    household: "fa-solid fa-house-chimney",
    business: "fa-solid fa-buildings",
}

const OfficeViewRecentItem = ({ 
    recent,
    onRecentCallClick,
    setViewCallMode,
}) => {
    const [isVoicemailPlaybackOpen, setIsVoicemailPlaybackOpen] = useState(false);
    const isUnverified = !recent?.contacts?.[0]?.account_id && !recent?.contacts?.[0]?.contact_id;

    return (
        <div 
            key={recent.call_uuid} 
            onClick={() => {
                if (!isUnverified) {
                    onRecentCallClick(recent.call_uuid);
                    setViewCallMode(true);
                }
            }} 
            className={`OfficeViewRecents__element ${isUnverified ? "" : "OfficeViewRecents__element--hover"}`}
        >
            <div style={{flex: 1, display: 'flex', gap: 10}}>
                <Popup
                    trigger={ <div className={`RecentCalls__missedCallBorder ${!recent.unreturned ? "RecentCalls__missedCallBorder--invisible" : ""}`} /> }
                    content={"Call Not Returned"}
                    inverted
                    disabled={recent.not_returned}
                />
                <div>
                    <div><b>{DateTimeUtils.formatEpoch(recent.start_ts, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(recent.start_ts, DateTimeUtils.TIME_FORMAT)}</div>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 10, flex: 3}}>
                <Icon className={`${DIRECTION_ICONS[recent.direction] || ""}`} />
                <div>
                    <div>
                        {recent?.contacts?.[0] && recent?.contacts?.[0]?.name && (
                            <Icon className={PERSON_TYPE_ICON[recent.contacts?.[0]?.type || ""]} size={"small"} style={{marginRight: 7}} />
                        )}
                        <ButtonLink style={{paddingLeft: 0}}>{recent.contacts?.[0]?.name || "Unknown"}</ButtonLink>
                    </div>
                    <span className={`RecentCalls__resultColor${!!recent.connected_ts ? "--green" : "--red"}`}>{recent.system_result}</span>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                <div>
                    <span>{PhoneUtil.formatPhoneNumber(recent.contacts?.[0]?.phone)}</span>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 7, flex: 2}}>
                <ButtonLink onClick={(event) => event.stopPropagation()}>
                    <Icon className='fa-solid fa-phone' size={"large"} />
                </ButtonLink>
                <ButtonLink onClick={(event) => event.stopPropagation()} disabled>
                    <Icon className='fa-solid fa-split' size={"large"} />
                </ButtonLink>
                <Popup
                    style={{minWidth:400}}
                    trigger={
                        <Icon className={`fa-solid fa-voicemail ${!recent.voicemail ? `RecentCalls__missedCallBorder--invisible` : ""}`} size="large" color="primary" />
                    }
                    on="click"
                    content={
                        <VoicemailPlayback
                            onClose={(event) => {
                                event.stopPropagation();
                                setIsVoicemailPlaybackOpen(false)
                            }}
                            className={`audio-playback-${recent.call_uuid}`}
                            link={recent.voicemail}
                        />
                    }
                    position="bottom center"
                    open={isVoicemailPlaybackOpen}
                    onOpen={(event) => {
                        event.stopPropagation();
                        setIsVoicemailPlaybackOpen(true);
                    }}
                />
            </div>
        </div>
    )
}

export default OfficeViewRecentItem;