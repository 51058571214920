import React, { useState, useEffect } from 'react';
import './index.scss';
import { Icon } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';

const OfficeViewHeader = ({ state = 'idle', phoneNumber, currentCallTicker, callDirection }) => {
  
    return (
        <div className={`OfficeViewHeader ${state ? `OfficeViewHeader--${state}` : ""}`}>
            <div style={{flex: 1}}>
                {/* <div className='OfficeViewHeader__ball' /> //TODO: CER-4483: Figure out */}
                {(state === "ringing" || state === "active") &&
                <>
                    {/* <div className="OfficeViewHeader__line">1</div> //Removed  */}
                    <Icon className={`fa-solid ${state === "ringing" ? "fa-phone-volume" : callDirection === "outbound" ? "fa-phone-arrow-up-right" : "fa-phone-arrow-down-left"} OfficeViewHeader__ringing-icon`} />
                    <div className="OfficeViewHeader__number">{PhoneUtil.formatPhoneNumber(phoneNumber)}</div>
                    <div className="OfficeViewHeader__time-ticker">{state === "active" && PhoneUtil.timeTicker(currentCallTicker)}</div>
                </>
                }
            </div>
            <Icon className={"fa-solid fa-arrow-up-right-from-square OfficeViewHeader__icon"} />
        </div>
    )
};

export default OfficeViewHeader;